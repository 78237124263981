export const APIGateways = {
	// Dev: "http://localhost:7071/api/",
	Dev: "https://func-reportgenerator-support-dev-001.azurewebsites.net/api/",
	Production: "https://func-reportgenerator-support-prod-001.azurewebsites.net/api/",
};

export const APIGatewayCode = {
	Dev: "bDCDqHpYZB9EAV1EZ3cMgqu6z_LzEG5-XcPx52zPB0TxAzFuBQcxNw==",
	Production: "Ly1oPmEt5hx1ZcrN3z8jGfVhz1p8KKeD-lNqg4omS9ziAzFu03YqZA==",
};
