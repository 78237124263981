import { faEnvelope } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListCard } from "@streetsheaver/sh-ui";
import { useQuery } from "@tanstack/react-query";
import React, { useContext } from "react";
import { useNavigate } from "react-router";
import { CopyText } from "../../components/CopyText/CopyText";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NoEnvSelected from "../../components/NoEnvSelected/NoEnvSelected";
import { EnvironmentContext } from "../../context/EnvironmentContextProvider";
import { callAPI } from "../../helpers/callAPI";
import { useTenants } from "../../hooks/useTenants";
import "./Tenants.scss";

export default function Tenants() {
	const { selectedEnv } = useContext(EnvironmentContext);
	const navigate = useNavigate();
	const { tenantsLoading, tenants } = useTenants();

	const { data: activeUsers, isLoading: activeUsersLoading } = useQuery({
		queryKey: ["Active Users", selectedEnv],
		queryFn: async () => {
			const monthlyUsers = await callAPI({ url: `analytics/monthly-users`, selectedEnv: selectedEnv });
			return monthlyUsers.data;
		},
	});

	if (selectedEnv === null) return <NoEnvSelected />;
	if (tenantsLoading || activeUsersLoading) return <LoadingSpinner />;
	return (
		<div className="tenants">
			<div className="tenants__title">
				<div className="tenants__title__text">
					<h1 style={{ marginBottom: "4px" }}>Tenants</h1>
					<p style={{ textAlign: "center" }}>Total Monthly Active Users: {activeUsers?.length}</p>
				</div>
				<div></div>
			</div>
			<div className="tenants__cards">
				{tenants?.map((tenant) => {
					return (
						<ListCard
							key={tenant.id}
							title={
								<CopyText textToCopy={tenant.name}>
									<div className="tenants__cards__card__title">
										<p style={{ fontSize: "18px", fontWeight: 600 }}>
											{tenant.name} {tenant.isMultiOrganisation ? `(Multi-Org) - ${tenant.groupName}` : ""}
										</p>
										<FontAwesomeIcon
											style={{ color: tenant?.emailSettings ? "green" : "var(--system-critical)" }}
											icon={faEnvelope}
											title={tenant?.emailSettings ? "Email settings configured" : "No email settings configured"}
										/>
									</div>
								</CopyText>
							}
							subtext1={
								<CopyText textToCopy={tenant.id}>
									<span style={{ fontSize: "12px" }}>{`Tenant ID: ${tenant.id}`}</span>
								</CopyText>
							}
							subtext2={`Monthly Active Users: ${activeUsers?.reduce(
								(acc, obj) => (obj?.tenantId === tenant.reportGeneratorTenant ? acc + 1 : acc),
								0,
							)} `}
							{...(tenant.isMultiOrganisation && {
								subtext3: (
									<CopyText textToCopy={tenant.groupId}>
										<span style={{ fontSize: "12px" }}>GroupId: {tenant.groupId}</span>
									</CopyText>
								),
							})}
							button1={{
								label: "View Datasources",
								onClick: () => navigate(`/${tenant.name}/datasources`, { state: { id: tenant.id } }),
							}}
							button2={{
								label: "Manage Tenant Settings",
								type: "CTA",
								onClick: () =>
									navigate(`/${tenant.name}/settings/email`, {
										state: { id: tenant.id, repGenId: tenant.reportGeneratorTenant },
									}),
							}}
						/>
					);
				})}
			</div>
		</div>
	);
}
