import React, { useContext, useEffect, useState } from "react";
import "./EmailSettings.scss";
import { useLocation, useParams } from "react-router";
import { EnvironmentContext } from "../../context/EnvironmentContextProvider";
import { Button, Checkbox, Popup, Textbox, Toast } from "@streetsheaver/sh-ui";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useTenantDatasources } from "../../hooks/useTenantDatasources";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { callAPI } from "../../helpers/callAPI";
import { useTenants } from "../../hooks/useTenants";

export default function EmailSettings() {
	const { selectedEnv } = useContext(EnvironmentContext);
	const tenantName = useParams()?.tenantName;
	const repGenTenantId = useLocation()?.state?.repGenId;
	const tenantId = useLocation()?.state?.id;
	const [emailSettingsForm, setEmailSettingsForm] = useState({
		fromAddress: null,
		fromName: null,
		password: null,
		port: 587,
		replyToAddress: null,
		replyToName: null,
		server: null,
		tenantId: repGenTenantId,
		username: null,
		enabledDatasources: [],
	});
	const { tenants, tenantsLoading } = useTenants();
	const [toast, setToast] = useState({ visible: false, type: "", text: "" });
	const [emailErrors, setEmailErrors] = useState([]);
	const { datasources, datasourcesLoading } = useTenantDatasources(selectedEnv, tenantId, repGenTenantId);
	const [deleteEmailSettingsConfirmation, setDeleteEmailSettingsConfirmation] = useState(false);

	const { data: emailSettings, isLoading: emailSettingsLoading } = useQuery({
		queryKey: ["Email Settings", selectedEnv, repGenTenantId],
		queryFn: async () => {
			const repGenId = tenants?.find(
				(tenant) => tenant.reportGeneratorTenant === repGenTenantId,
			)?.reportGeneratorTenant;
			const res = await callAPI({ url: `tenants/${repGenId}/email-settings`, selectedEnv: selectedEnv });
			return res.data;
		},
		refetchOnWindowFocus: false,
		retry: (count, error) => {
			if (error?.response?.status === 404) return false;
			return true;
		},
		enabled: !!tenants,
	});

	useEffect(() => {
		if (emailSettings) setEmailSettingsForm(emailSettings);
	}, [emailSettings]);

	const updateEmailSettings = (key, value) => {
		setEmailErrors((prev) => prev?.filter((e) => e !== key));
		setEmailSettingsForm({
			...emailSettingsForm,
			[key]: value?.length > 0 ? value : typeof value === "number" ? value : null,
		});
	};

	const checkValidEmailSettings = () => {
		const optionalKeys = ["password", "replyToAddress", "replyToName", "enabledDatasources", "port"];
		const emptyValues = [];
		for (const key in emailSettingsForm) {
			if (!optionalKeys.includes(key)) {
				if (emailSettingsForm[key] === "" || emailSettingsForm[key] === null || emailSettingsForm[key]?.length === 0) {
					emptyValues.push(key);
				}
			}
		}
		setEmailErrors(emptyValues);
		return emptyValues?.length === 0;
	};

	const postEmailSettings = useMutation({
		mutationFn: async () => {
			const isValid = checkValidEmailSettings();
			if (!isValid) return;
			const repGenId = tenants?.find(
				(tenant) => tenant.reportGeneratorTenant === repGenTenantId,
			)?.reportGeneratorTenant;
			return await callAPI({
				body: {
					...emailSettingsForm,
					tenantId: repGenId,
				},
				url: `tenants/${repGenId}/email-settings`,
				selectedEnv: selectedEnv,
				method: "post",
			});
		},
		onSuccess: (res) => {
			if (!res) return;
			setToast({ visible: true, text: "Succesfully updated email settings", type: "success" });
		},
		onError: () => setToast({ visible: true, text: "Failed to update email settings", type: "critical" }),
	});

	const deleteEmailSettings = useMutation({
		mutationFn: async () => {
			const repGenId = tenants?.find(
				(tenant) => tenant.reportGeneratorTenant === repGenTenantId,
			)?.reportGeneratorTenant;
			return await callAPI({
				url: `tenants/${repGenId}/email-settings`,
				selectedEnv: selectedEnv,
				method: "delete",
			});
		},
		onSuccess: (res) => {
			if (!res) return;
			setToast({ visible: true, text: "Succesfully deleted email settings", type: "success" });
			setEmailSettingsForm({
				fromAddress: null,
				fromName: null,
				password: null,
				port: null,
				replyToAddress: null,
				replyToName: null,
				server: null,
				tenantId: repGenTenantId,
				username: null,
				enabledDataSources: [],
			});
			setDeleteEmailSettingsConfirmation(false);
		},
		onError: () => setToast({ visible: true, text: "Failed to delete email settings", type: "critical" }),
	});

	if (emailSettingsLoading || tenantsLoading) return <LoadingSpinner />;

	if (!deleteEmailSettings.isLoading)
		return (
			<div className="email">
				<Textbox
					title={"Email Server Address"}
					isRequired
					value={emailSettingsForm?.server}
					onChange={(e) => updateEmailSettings("server", e.target.value)}
					isError={emailErrors.includes("server")}
					errorMessage={"Server is a required field"}
				/>
				{!emailSettingsLoading && (
					<Textbox
						title={"Email Server Port (Defaults to 587. Port 25 is disabled and cannot be used)"}
						value={emailSettingsForm?.port}
						onChange={(e) => {
							const regex = /^(?:[0-9]+|)$/;
							if (regex.test(e.target.value)) {
								updateEmailSettings("port", Number(e.target.value));
							}
						}}
						isRequired
						isError={emailErrors.includes("port")}
						errorMessage={"Port is a required field"}
					/>
				)}
				<Textbox
					title={"From Address"}
					isRequired
					value={emailSettingsForm?.fromAddress}
					onChange={(e) => updateEmailSettings("fromAddress", e.target.value)}
					isError={emailErrors.includes("fromAddress")}
					errorMessage={"From Address is a required field"}
				/>
				<Textbox
					title={"From Name"}
					isRequired
					value={emailSettingsForm?.fromName}
					onChange={(e) => updateEmailSettings("fromName", e.target.value)}
					isError={emailErrors.includes("fromName")}
					errorMessage={"From Name is a required field"}
				/>
				<Textbox
					title={"Reply To Address"}
					value={emailSettingsForm?.replyToAddress}
					onChange={(e) => updateEmailSettings("replyToAddress", e.target.value)}
				/>
				<Textbox
					title={"Reply To Name"}
					value={emailSettingsForm?.replyToName}
					onChange={(e) => updateEmailSettings("replyToName", e.target.value)}
				/>
				<Textbox
					title={"Username"}
					isRequired
					value={emailSettingsForm?.username}
					onChange={(e) => updateEmailSettings("username", e.target.value)}
					isError={emailErrors.includes("username")}
					errorMessage={"Username is a required field"}
				/>
				<Textbox
					title={"Password (Can be left blank if previously set to keep same password)"}
					isRequired
					value={emailSettingsForm?.password}
					onChange={(e) => updateEmailSettings("password", e.target.value)}
				/>
				{!datasourcesLoading && (
					<div>
						<p>Enabled Datasources</p>
						<div className="email__checkboxes">
							{datasources?.map((d, i) => (
								<Checkbox
									key={i}
									label={d?.name}
									checked={emailSettingsForm?.enabledDataSources?.includes(d?.id)}
									onChange={(e) => {
										const datasources = emailSettingsForm?.enabledDataSources ?? [];
										e.target.checked
											? setEmailSettingsForm({
													...emailSettingsForm,
													enabledDataSources: [...datasources, d?.id],
											  })
											: setEmailSettingsForm({
													...emailSettingsForm,
													enabledDataSources: emailSettingsForm?.enabledDataSources?.filter((e) => e !== d?.id),
											  });
									}}
								/>
							))}
						</div>
					</div>
				)}
				<div className="email__buttons">
					<div>
						<Button unpadded text={"Save Email Settings"} type={"CTA"} onClick={() => postEmailSettings.mutate()} />
					</div>
					<div>
						<Button unpadded text={"Delete Email Settings"} onClick={() => setDeleteEmailSettingsConfirmation(true)} />
					</div>
				</div>
				<Popup
					visible={deleteEmailSettingsConfirmation}
					onOutsideClick={() => setDeleteEmailSettingsConfirmation(false)}
					onCloseButtonClick={() => setDeleteEmailSettingsConfirmation(false)}
					renderContent={() => (
						<div className="email__delete">
							<p>Are you sure you want to delete email settings for {tenantName}?</p>
							<p>This action cannot be undone</p>
							<div>
								<Button text={"Confirm"} onClick={() => deleteEmailSettings.mutate()} />
							</div>
						</div>
					)}
					noHeader
					noPadding
				/>
				<Toast
					visible={toast?.visible}
					renderContent={toast?.text}
					timer={3000}
					timeoutFunction={() => setToast((prev) => ({ ...prev, visible: false }))}
					type={toast.type}
				/>
			</div>
		);
}
