import { faCheck, faCircleExclamation, faDotCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListCard, Popup } from "@streetsheaver/sh-ui";
import { useMutation } from "@tanstack/react-query";
import React, { useContext, useState } from "react";
import { useLocation, useParams } from "react-router";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NoEnvSelected from "../../components/NoEnvSelected/NoEnvSelected";
import { EnvironmentContext } from "../../context/EnvironmentContextProvider";
import { callAPI } from "../../helpers/callAPI";
import { useTenantDatasources } from "../../hooks/useTenantDatasources";
import "./Datasources.scss";
import { useTenants } from "../../hooks/useTenants";

const DatasourceBadgeColours = {
	Online: "green",
	Offline: "red",
	UnderMaintenance: "orange",
	ConnectionError: "grey",
};

const RenderCard = ({ datasource, setTestDatasourcePopup }) => {
	const { selectedEnv } = useContext(EnvironmentContext);
	const { tenants } = useTenants();
	const tenantName = useParams().tenantName;
	const tenantId = tenants?.find((t) => t.name === tenantName)?.reportGeneratorTenant;

	const testDatasource = useMutation({
		mutationKey: ["Test Datasource", datasource.id],
		mutationFn: async (datasource) => {
			const res = await callAPI({
				url: `tenants/${tenantId}/data-sources/${datasource.id}/test`,
				selectedEnv: selectedEnv,
			});
			setTestDatasourcePopup({ visible: true, result: { ...datasource, ...res.data } });
			return res.data;
		},
	});
	return (
		<ListCard
			key={datasource.id}
			title={
				<div className="datasources__cards__title">
					<p className="datasources__cards__title__text">{`${datasource.name}`}</p>
					<FontAwesomeIcon
						className="datasources__cards__title__status"
						icon={faDotCircle}
						color={DatasourceBadgeColours[datasource.status]}
						title={datasource.status}
					/>
				</div>
			}
			subtext1={`Datasource ID: ${datasource.id}`}
			subtext2={`Tenant Server ID: ${datasource.tenantServerDatasourceId}`}
			button2={{
				label: "Test Datasource",
				onClick: () => testDatasource.mutate(datasource),
			}}
		/>
	);
};

export default function Datasources() {
	const { selectedEnv } = useContext(EnvironmentContext);
	const [testDatasourcePopup, setTestDatasourcePopup] = useState({ visible: false, result: {} });
	const tenantName = useParams()?.tenantName;
	const tenantId = useLocation()?.state?.id;
	const { datasources, datasourcesLoading } = useTenantDatasources(selectedEnv, tenantId);

	if (selectedEnv === null) return <NoEnvSelected />;
	if (datasourcesLoading) return <LoadingSpinner />;

	return (
		<div className="datasources">
			<div className="datasources__title">
				<div className="datasources__title__button"></div>
				<h1 className="datasources__title__text">Manage Datasources - {tenantName}</h1>
				<div></div>
			</div>
			<div className="datasources__cards">
				{datasources?.map((datasource) => (
					<RenderCard key={datasource.id} datasource={datasource} setTestDatasourcePopup={setTestDatasourcePopup} />
				))}
			</div>
			<Popup
				visible={testDatasourcePopup?.visible}
				onCloseButtonClick={() => setTestDatasourcePopup((prev) => ({ ...prev, visible: false }))}
				onOutsideClick={() => setTestDatasourcePopup((prev) => ({ ...prev, visible: false }))}
				noHeader
				noPadding
				renderContent={() => {
					const ukWestResult = testDatasourcePopup?.result?.ukWest;
					const ukSouthResult = testDatasourcePopup?.result?.ukSouth;
					if (ukWestResult || ukSouthResult) {
						return (
							<div className="testingPopup">
								<p className="testingPopup__title">Connection Test - {testDatasourcePopup?.result?.name}</p>
								{[ukWestResult, ukSouthResult].map((r, i) => (
									<>
										<div className="testingPopup__result">
											<p className="testingPopup__result__text">
												Connection Result {i === 0 ? "UK West" : "UK South"} - {r?.status}
											</p>
											{r?.status === "Success" || r?.status === "Elevated" ? (
												<FontAwesomeIcon icon={faCheck} className="testingPopup__tick" />
											) : (
												<FontAwesomeIcon icon={faCircleExclamation} className="testingPopup__cross" />
											)}
										</div>
										{r?.status === "Success" && <p>{r?.resultMessage}</p>}
										{r?.status === "Elevated" && (
											<>
												<p>{r?.resultMessage}</p>
												<p>
													Warning: Elevated permissions may mean this connection allows destructive operations in SQL
													Direct queries
												</p>
											</>
										)}
										{r?.status === "NotFound" && <p>{r?.resultMessage}</p>}
										{r?.status === "Errored" && <p>{r?.resultMessage || r?.title}</p>}
									</>
								))}
							</div>
						);
					}
					return (
						<div className="testingPopup">
							<p className="testingPopup__title">Connection Test - {testDatasourcePopup?.result?.name}</p>
							<div className="testingPopup__result">
								<p className="testingPopup__result__text">Connection Result - {testDatasourcePopup?.result?.status}</p>
								{testDatasourcePopup?.result?.status === "Success" ||
								testDatasourcePopup?.result?.status === "Elevated" ? (
									<FontAwesomeIcon icon={faCheck} className="testingPopup__tick" />
								) : (
									<FontAwesomeIcon icon={faCircleExclamation} className="testingPopup__cross" />
								)}
							</div>
							{testDatasourcePopup?.result?.status === "Success" && <p>{testDatasourcePopup?.result?.resultMessage}</p>}
							{testDatasourcePopup?.result?.status === "Elevated" && (
								<>
									<p>{testDatasourcePopup?.result?.resultMessage}</p>
									<p>
										Warning: Elevated permissions may mean this connection allows destructive operations in SQL Direct
										queries
									</p>
								</>
							)}
							{testDatasourcePopup?.result?.status === "NotFound" && (
								<p>{testDatasourcePopup?.result?.resultMessage}</p>
							)}
							{testDatasourcePopup?.result?.status === "Errored" && (
								<p>{testDatasourcePopup?.result?.resultMessage || testDatasourcePopup?.result?.title}</p>
							)}
						</div>
					);
				}}
			/>
		</div>
	);
}
