import { msalInstance } from "..";
import { APIGatewayCode, APIGateways } from "./APIGateways";
import axios from "axios";

export const getToken = async () => {
	const account = msalInstance.getAllAccounts()[0];
	try {
		if (!account) {
			console.info(`No account found for home tenant. Redirecting to select an account for token acquisistion`);
			const response = await msalInstance.acquireTokenRedirect();
			return "Bearer " + response.accessToken;
		}
		const response = await msalInstance.acquireTokenSilent({ account: account });
		return "Bearer " + response.accessToken;
	} catch (error) {
		const response = await msalInstance.acquireTokenRedirect();
		return "Bearer " + response.accessToken;
	}
};

export const callAPI = async ({ url, method = "get", selectedEnv, body, params = "" }) => {
	if (!selectedEnv) throw new Error("No Environment Passed To callAPI");
	if (!url) throw new Error("No URL passed to callAPI");

	const API_URL = APIGateways[selectedEnv];
	const API_CODE = APIGatewayCode[selectedEnv];
	const endpoint = `${API_URL}${url}?code=${API_CODE}${params}`;
	const user = msalInstance.getAllAccounts()[0];
	const Authorization = await getToken();
	const config = {
		headers: {
			"Content-Type": "application/json",
			"X-Tenant": user.tenantId,
			"X-Identity": user.localAccountId,
			"X-DisplayName": user.name ?? user.username,
			Authorization,
		},
	};

	switch (method.toLowerCase()) {
		case "get":
			return await axios.get(endpoint, config);
		case "post":
			return await axios.post(endpoint, body, config);
		case "patch":
			return await axios.patch(endpoint, body, config);
		case "delete":
			return await axios.delete(endpoint, { ...config, data: body });

		default:
			return await axios.get(endpoint);
	}
};
