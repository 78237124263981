import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
	auth: {
		clientId: "835fd79b-9087-406b-889e-8167cfbf864d",
		authority: "https://login.microsoftonline.com/73b2231a-113f-4276-954d-6aa6139488ca/",
		redirectUri: window.location.protocol + "//" + window.location.host,
	},
	cache: {
		cacheLocation: "localStorage",
		storeAuthStateInCookie: false,
	},
	system: {
		loggerOptions: {
			loggerCallback: (level, message, containsPii) => {
				if (containsPii) {
					return;
				}
				switch (level) {
					case LogLevel.Error:
						// eslint-disable-next-line no-console
						console.error(message);
						return;
					default:
						return;
				}
			},
		},
	},
};

export const loginRequest = {
	scopes: ["api://43e92ffc-e310-41d7-b9eb-b1ae93127b50/RepGen.User"],
	prompt: "select_account",
};