import { useQuery, useQueryClient } from "@tanstack/react-query";
import { callAPI } from "../helpers/callAPI";
import { useTenants } from "./useTenants";

export const useTenantDatasources = (selectedEnv, tenantId, repGenId) => {
	const { tenants } = useTenants();
	const queryClient = useQueryClient();
	const datasourcesInCache = queryClient.getQueryData(["Datasources", selectedEnv, tenantId]);
	const { data: datasources, isLoading: datasourcesLoading } = useQuery({
		queryKey: ["Datasources", selectedEnv, tenantId],
		queryFn: async () => {
			if (!tenantId) return null;
			if (datasourcesInCache) return datasourcesInCache;
			const groupId = tenants?.find((tenant) => {
				if (typeof repGenId !== "undefined") return tenant.reportGeneratorTenant === repGenId;
				return tenant.id === tenantId;
			})?.groupId;
			const res = await callAPI({
				url: `tenants/${tenantId}/data-sources`,
				selectedEnv: selectedEnv,
				...(groupId && { params: `&groupId=${groupId}` }),
			});
			return res.data;
		},
		refetchOnWindowFocus: false,
		enabled: !!tenants,
	});
	return { datasources, datasourcesLoading };
};
