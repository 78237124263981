import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { EnvironmentContext } from "../context/EnvironmentContextProvider";
import { callAPI } from "../helpers/callAPI";

export const useTenants = () => {
	const { selectedEnv } = useContext(EnvironmentContext);

	const { data: tenants, isLoading: tenantsLoading } = useQuery({
		queryKey: ["Tenants", selectedEnv],
		queryFn: async () => {
			const tenantsRes = await callAPI({ url: "tenants", selectedEnv: selectedEnv });
			const promises = tenantsRes.data.map(async (tenant) => {
				try {
					const res = await callAPI({
						url: `tenants/${tenant.reportGeneratorTenant}/email-settings`,
						selectedEnv: selectedEnv,
					});
					tenant.emailSettings = res.data;
				} catch (error) {
					tenant.emailSettings = null;
				}
				return tenant;
			});

			const result = await Promise.all(promises);
			return result;
		},
		refetchOnWindowFocus: false,
	});

	return { tenantsLoading, tenants };
};
